import Home from "../views/Home.vue";
import { isVVV } from "@/helpers/siteIdentifier";

export default [
  { path: "/:pathMatch(.*)*", name: "NotFound", redirect: { name: "Login" } },
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: { name: "Login" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => {
      return isVVV()
        ? import("../views/VVV/VVVLoginPage.vue")
        : import("../views/v3/LoginNext.vue");
    },
    meta: { isVVV: isVVV() },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => {
      return isVVV()
        ? import("../views/VVV/VVVDashboard.vue")
        : import("../views/v3/DashboardNext.vue");
    },
  },
  {
    path: "/user-profile",
    name: "UserSettings",
    component: () => {
      return isVVV()
        ? import("../views/VVV/VVVUserSetting.vue")
        : import("../views/v3/UserProfileNext.vue");
    },
  },
  {
    path: "/asset-labels",
    name: "AssetLabels",
    component: () => import("../views/v3/AssetLabel/AssetLabelNext"),
  },
  {
    path: "/asset-label-settings/:id",
    name: "AssetLabelSettings",
    component: () => import("../views/v3/AssetLabel/AssetLabelSettingNext"),
  },
  {
    path: "/assets",
    name: "Assets",
    component: () => import("../views/v3/AssetNext"),
  },
  {
    path: "/users",
    name: "UserManagement",
    component: () => import("../views/v3/Clients/ClientsNext"),
  },
  {
    path: "/upload-earnings-report",
    name: "UploadAnalytics",
    component: () => import("../views/v3/UploadEarningsReportNext.vue"),
  },
  {
    path: "/users/:id/revenue-report",
    name: "MonthlyReport",
    component: () => import("../views/v3/Clients/ClientsMonthlyReportNext.vue"),
  },
  {
    path: "/users/create-account",
    name: "AddUser",
    component: () => import("../views/v3/Clients/CreateClientNext.vue"),
  },
  // Old
  // {
  //   path: "/Users/:id/Adjustments",
  //   name: "Deductions",
  //   component: () => import("../views/v2/UserAdjustmentsNew"),
  //   props: true,
  // },
  {
    path: "/users/:id/adjustments",
    name: "AdjustmentsNew",
    component: () => import("../views/v3/Clients/ClientsAdjustmentNext"),
    props: true,
  },
  {
    path: "/users/:id/partner-percentage",
    name: "PartnerPercentage",
    component: () => import("../views/v3/Clients/ClientsPartnerPercentageNext"),
    props: true,
  },
  {
    path: "/users/:link/activate-account",
    name: "Register",
    component: () => import("../views/v3/Clients/ActivateUserNext.vue"),
  },
  {
    path: "/users/:link/reset-password",
    name: "Reset",
    component: () => import("../views/v3/Clients/ResetPasswordNext.vue"),
  },
  {
    path: "/users/:id/update-account",
    name: "UpdateUser",
    component: () => import("../views/v3/Clients/UpdateClientsNext"),
  },
  {
    path: "/daily-sync",
    name: "DailyVideoUpdatesManagement",
    component: () => import("../views/v3/DailySync/DailySyncNext"),
  },
  {
    path: "/daily-sync/add-creator-notification",
    name: "AddCreator",
    component: () => import("../views/v3/DailySync/AddCreatorNotificationNext"),
  },
  // {
  //   path: "/daily-sync/notifications",
  //   name: "Notifications",
  //   component: () => import("../views/v2/DailyVideoUpdateNotificationListNew"),
  // },
  {
    path: "/new-sync",
    name: "DailyVideoUpdate",
    component: () => import("../views/v3/NewSync/NewSyncNext"),
  },
  {
    path: "/daily-sync/:id/update-creator-notification",
    name: "UpdateCreator",
    component: () =>
      import("../views/v3/DailySync/UpdateCreatorNotificationNext"),
  },
  {
    path: "/daily-sync-report",
    name: "VideoUpdateReport",
    component: () => import("../views/v3/DailySync/DailySyncReportNext"),
  },
  {
    path: "/pex-collections",
    name: "Collections",
    component: () => import("../views/v3/PexCollection/PexCollectionNext"),
  },
  {
    path: "/pex-collections/:id/assets",
    name: "CollectionsAssets",
    component: () => import("../views/v3/PexCollection/PexCollectionAssetNext"),
  },
  {
    path: "/pex-collections/:id/assets/:assetId",
    name: "CollectionsAssetsView",
    component: () =>
      import("../views/v3/PexCollection/PexCollectionAssetViewNext"),
  },
  {
    path: "/assets-upload",
    name: "AssetsUpload",
    component: () => import("../views/v3/AssetUploadNext"),
  },
  {
    path: "/pex-copies",
    name: "Copies",
    component: () => import("../views/v3/PexCopiesNext"),
  },
  {
    path: "/pex-allowlist",
    name: "Whitelist",
    component: () => import("../views/v3/PexAllowlistNext"),
  },
  {
    path: "/NewClaim",
    name: "NewClaim",
    component: () => import("../views/VVV/NewClaim/NewClaim"),
  },
  {
    path: "/ClaimsList",
    name: "ClaimsList",
    component: () => import("../views/VVV/ClaimsList/ClaimsList"),
  },
  {
    path: "/BulkClaims",
    name: "BulkClaims",
    component: () => import("../views/VVV/BulkClaims"),
  },
];
